import React from "react";
import { createRoot } from 'react-dom/client';
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import "./index.css";

import App from './App';
import T3Email from './T3Email.js';
import JbfReport from './JbfReport.js';
import ScandataPreview from './ScandataPreview.js';
import ScandataReview from "./ScandataReview"; 
function TopMenu() {

    return (
        <Tabs defaultTab="main-home">
            <TabList>
                <Tab tabFor="main-home">Home</Tab>
                <Tab tabFor="main-maggie">Maggie</Tab>
                <Tab tabFor="main-scandata">Scan Data</Tab>
            </TabList>

            <TabPanel tabId="main-home">
                <h2>Login is not required at this time</h2>
            </TabPanel>

            <TabPanel tabId="main-maggie">
                <Tabs defaultTab="maggie-lfp" vertical className="vertical-tabs">
                    <TabList>
                        <Tab tabFor="maggie-lfp">LFP Report</Tab>
                        <Tab tabFor="maggie-tier3">Tier3 Email</Tab>
                        <Tab tabFor="maggie-jbf">JBF Report</Tab>
                    </TabList>

                    <TabPanel tabId="maggie-lfp" render={({ selected }) => selected ? (<App />) : (null)}/>

                    <TabPanel tabId="maggie-tier3" render={({ selected }) => selected ? (<T3Email />) : (null)} />

                    <TabPanel tabId="maggie-jbf" render={({ selected }) => selected ? (<JbfReport />) : (null)} />
                </Tabs>
            </TabPanel>


            <TabPanel tabId="main-scandata">
                <Tabs defaultTab="vertical-tab-one" vertical className="vertical-tabs">
                    <TabList>
                        <Tab tabFor="scandata-missing">Missing</Tab>
                        <Tab tabFor="scandata-transactions">Preview</Tab>
                        <Tab tabFor="vertical-tab-three">Review</Tab>
                    </TabList>

                    <TabPanel tabId="scandata-missing" render={({ selected }) => selected ? (<ScandataPreview format='missing' />) : (null)} />

                    <TabPanel tabId="scandata-transactions" render={({ selected }) => selected ? (<ScandataPreview format='preview'/>) : (null)} />

                    <TabPanel tabId="vertical-tab-three" render={({ selected }) => selected ? (<ScandataReview />) : (null)} />
                </Tabs>
            </TabPanel>
        </Tabs>    );
}

const root = createRoot(document.getElementById("root"));
root.render(<TopMenu />);
